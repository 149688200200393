import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class servicesUrl {
  // public url = 'https://localhost:44325';
  // public url = 'https://api2.oryxexpress.net:444';
  // public url = 'https://api2.ship.oceangp.net:444';
  // public url = 'https://api2.wcrg.org:444';
  //public url = 'https://api2.werig.org:444';
  // public url = 'https://api2.firstsourceco.com:444';
  // public url = ' https://api2.digitalcitye.com:444';
  //  public url = 'https://api2.yg-u.net:444';
  // public url = 'http://api.yg-u.net:444';
  //  public url = 'https://api2.technomed-ye.com:444';
  // public url = 'https://api2.alshamelah-ye.com:444';
  // public url = 'https://api2.yuniv.net:443';
  // public url = 'https://api2.spectragp.com:444';
  // public url = 'https://api2.siametricint.com:444';
  //  public url = 'https://usermanual.api2.newtouch.ws:444';
  // public url = 'https://api2.newtouch.dev:444';
  public url = 'https://api2.newtouch.dev:443';//jamal
  // public url = 'https://api2.amrangp.com:444';
  // public url = 'https://api.petraunited.com:444';
  // public url1 = 'http://10.0.0.150:5100';
  // public url = 'https://api2.ultranethost.com:444';
  // public url = 'https://api2.invuni.com:444';
  // public url = 'http://test1.prixerp.com';
  // public url = 'https://api2.corner-printing.com:444';
  // public url = 'https://api2.aljawhara-sa.co:444';
  // public url = 'https://api2.n-jeddah.com:444';
  // public url = 'https://api2.aqar.newtouch.dev:444';
  // public url = 'https://Oceanweb.api2.prixerp.com:444';
  // public url = 'https://api2.glintaps.com:444';
  // public url = 'https://api2.yathreb-ye.com:444';
  // public url = 'https://api2.gic-edu.net:444';
  // public url = 'https://api2.avisiongp.com:444';
  // public url = 'https://api2.mutamayiz.oceangp.net:444';
  // public url = 'https://api2.oceangp.net:444';
  // public url = 'https://api2.mart.oceangp.net:444';
  // public url = 'http://oceanweb.api2.prixerp.com';
}
